<template>
  <v-row>
    <v-col cols="12">
      
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Painel-Adm",
  data: () => ({

  }),
  methods: {

  }
};
</script>